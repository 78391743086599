import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { withPrefix } from "gatsby";

const containerStyle = {
  width: "100%",
  height: "450px",
};

const mapStyle = [
  {
    featureType: "administrative",
    elementType: "labels.text.fill",
    stylers: [{ color: "#444444" }],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [{ color: "#f2f2f2" }],
  },
  { featureType: "poi", elementType: "all", stylers: [{ visibility: "off" }] },
  {
    featureType: "road",
    elementType: "all",
    stylers: [{ saturation: -100 }, { lightness: 45 }],
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [{ visibility: "simplified" }],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.icon",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [{ color: "#1f264b" }, { visibility: "on" }],
  },
];

const Map = ({ location }) => {
  const center = location;

  return (
    <LoadScript googleMapsApiKey="AIzaSyAss5sBHVV-m2JLrZ5cTrtnzHpa3R-pUAo">
      <GoogleMap
        center={center}
        zoom={15}
        options={{ styles: mapStyle }}
        mapContainerStyle={containerStyle}
      >
        <Marker icon={withPrefix("/ico-pointer.png")} position={location} />
      </GoogleMap>
    </LoadScript>
  );
};

export default Map;
