import "styles/pages/page-contact.scss";

import React from "react";

import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import ContactForm from "components/ContactForm";
import Map from "components/Map";
import Breadcrumps from "components/Breadcrumps";

const ContactPage = ({ pageContext }) => {
  const seo = pageContext.seo;
  const pageData = pageContext.data.pageContact;

  return (
    <Layout showSchema>
      <Seo title={seo.title} description={seo.metaDesc} />
      <Breadcrumps data={pageContext.data.title} />
      <SubpageHeader title={pageContext.data.title} />
      <section className="page-contact">
        <div className="container">
          <div className="page-contact__baner">
            <img
              className="img-fluid desktop"
              src={require("assets/images/contact-baner.png").default}
              alt=""
            />
            <img
              className="img-fluid mobile"
              src={require("assets/images/contact-baner-mobile.png").default}
              alt=""
            />
          </div>

          <div className="row">
            <div className="col-md-5">
              <p className="page-contact__column-title">
                {pageData.openingTitleLeft}
              </p>
              <div
                className="page-contact__column-text"
                dangerouslySetInnerHTML={{
                  __html: pageData.openingTextLeft,
                }}
              ></div>
              <div className="page-contact__contact-item">
                <div className="page-contact__title">
                  {pageData.companyName}
                </div>
                <div
                  className="page-contact__text"
                  dangerouslySetInnerHTML={{ __html: pageData.companyDetails }}
                />
              </div>
            </div>
            <div className="col-md-7">
              <h2 className="page-contact__form-title">{pageData.copyCta}</h2>
              <div className="page-contact__desc">
                {pageData.copyText}
                <br />
                <br />
                <strong>{pageData.copyText2}</strong>
              </div>
              <ContactForm />
            </div>
          </div>
        </div>
      </section>

      <section className="contact-map">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="contact-map__map-info">
                <p className="page-contact__column-title page-contact__map-title">
                  {pageData.columnTitle}
                </p>
                <div className="page-contact__contact-item">
                  <div className="page-contact__title">
                    {pageData.openingTitle}
                  </div>
                  <div
                    className="page-contact__text"
                    dangerouslySetInnerHTML={{
                      __html: pageData.openingText,
                    }}
                  />
                </div>
                <div className="page-contact__contact-item">
                  <div className="page-contact__title">
                    {pageData.contactTitle}
                  </div>
                  <div
                    className="page-contact__text"
                    dangerouslySetInnerHTML={{
                      __html: pageData.contactText,
                    }}
                  />
                </div>
                <div className="page-contact__contact-item">
                  <p className="page-contact__title">{pageData.addressTitle}</p>
                  <div
                    className="page-contact__text"
                    dangerouslySetInnerHTML={{ __html: pageData.addressText }}
                  />
                </div>
                <div className="page-contact__contact-item">
                  <div className="page-contact__phones-wrapper">
                    <a
                      href={`tel:+48${pageData.contactTel}`}
                      className="page-contact__contact-link page-contact__contact-link--phone"
                    >
                      {pageData.contactTel}
                    </a>
                    <a
                      href={`tel:+48${pageData.contactTelSecond}`}
                      className="page-contact__contact-link page-contact__contact-link--phone"
                    >
                      {pageData.contactTelSecond}
                    </a>
                  </div>
                  <a
                    href={`mailto:${pageData.contactMail}`}
                    className="page-contact__contact-link page-contact__contact-link--email"
                  >
                    {pageData.contactMail}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-7 mt-4 mt-md-0">
              <div className="contact-map__map-wrapper">
                {/* <div className="contact-map__text">
                  <h2>Lokalizacja poradni</h2>
                  <div
                    className="contact-map__address"
                    dangerouslySetInnerHTML={{ __html: pageData.addressText }}
                  />
                </div> */}
                <Map
                  location={{
                    lat: 52.3465118,
                    lng: 21.2439385,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-map">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="contact-map__map-info">
                <p className="page-contact__column-title page-contact__map-title">
                  {pageData.newClinicColumnTitle}
                </p>
                <div className="page-contact__contact-item">
                  <div className="page-contact__title">
                    {pageData.newClinicOpening}
                  </div>
                  <div
                    className="page-contact__text"
                    dangerouslySetInnerHTML={{
                      __html: pageData.newClinicOpeningInfo,
                    }}
                  />
                </div>
                <div className="page-contact__contact-item">
                  <div className="page-contact__title">
                    {pageData.newClinicTel}
                  </div>
                  <div
                    className="page-contact__text"
                    dangerouslySetInnerHTML={{
                      __html: pageData.newClinicTelInfo,
                    }}
                  />
                </div>
                <div className="page-contact__contact-item">
                  <p className="page-contact__title">
                    {pageData.newClinicAddress}
                  </p>
                  <div
                    className="page-contact__text"
                    dangerouslySetInnerHTML={{
                      __html: pageData.newClinicAddressInfo,
                    }}
                  />
                </div>
                <div className="page-contact__contact-item">
                  <div className="page-contact__phones-wrapper">
                    <a
                      href={`tel:+48${pageData.newClinicPhone}`}
                      className="page-contact__contact-link page-contact__contact-link--phone"
                    >
                      {pageData.newClinicPhone}
                    </a>
                  </div>
                  <a
                    href={`mailto:${pageData.newClinicEmail}`}
                    className="page-contact__contact-link page-contact__contact-link--email"
                  >
                    {pageData.newClinicEmail}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-7 mt-4 mt-md-0">
              <div className="contact-map__map-wrapper">
                {/* <div className="contact-map__text">
                  <h2>Lokalizacja poradni</h2>
                  <div
                    className="contact-map__address"
                    dangerouslySetInnerHTML={{ __html: pageData.addressText }}
                  />
                </div> */}
                <Map
                  location={{
                    lat: 52.3527687,
                    lng: 21.0383051,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ContactPage;
