import "./contact-form.scss";
import React, { useState } from "react";
import axios from "axios";
import { navigate } from "gatsby";

const ContactForm = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [legal, setLegal] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const resetForm = () => {
    setIsSending(false);
    setEmail("");
    setPhone("");
    setMessage("");
    setFullName("");
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setIsSending(true);

    let formData = new FormData();

    formData.set("fullName", fullName);
    formData.set("email", email);
    formData.set("phone", phone);
    formData.set("message", message);

    axios({
      method: "post",
      url: "https://wp.spokojwglowie.pl/wp-json/contact-form-7/v1/contact-forms/5/feedback/",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(() => {
        resetForm();
        navigate("/kontakt-potwierdzenie/");
      })
      .catch((err) => {
        console.log("Submit error");
        console.log(err);
      });
  };
  return (
    <form className="contact-form" onSubmit={formSubmit}>
      <div className="contact-form__input-group">
        <input
          name="fullName"
          id="fullName"
          type="text"
          className="contact-form__input"
          required
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
        />
        <label htmlFor="fullName" className="contact-form__label">
          Imię i nazwisko
        </label>
      </div>
      <div className="contact-form__input-group">
        <input
          name="email"
          id="email"
          type="email"
          className="contact-form__input"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label htmlFor="email" className="contact-form__label">
          Adres email*
        </label>
      </div>
      <div className="contact-form__input-group">
        <input
          name="phone"
          id="phone"
          type="tel"
          className="contact-form__input"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <label htmlFor="phone" className="contact-form__label">
          Telefon
        </label>
      </div>
      <div className="contact-form__input-group">
        <textarea
          name="message"
          id="message"
          required
          className="contact-form__input"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <label htmlFor="message" className="contact-form__label">
          Treść wiadomości
        </label>
      </div>
      <div className="contact-form__small-info">
        * Należy uzupełnić przynajmniej jedną formę kontaktu poprzez, którą
        skontaktujemy się z Tobą.
      </div>
      <div className="checkbox-wrapper">
        <label className="contact-form__checkbox-wrapper">
          <input
            name="legal"
            id="legal"
            type="checkbox"
            required
            className="contact-form__checkbox"
            onChange={(e) => setLegal(!legal)}
            value="   Wyrażam dobrowolną zgodę na przetwarzanie moich danych osobowych przez
                        SPOKÓJ W GŁOWIE SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ z siedzibą w
                        Kobyłce (05-230); KRS 0000890483; NIP 125-171-55-47; REGON 388115171."
          />
          <span className="contact-form__acceptance-text">
            Wyrażam dobrowolną zgodę na przetwarzanie moich danych osobowych
            przez SPOKÓJ W GŁOWIE SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ z
            siedzibą w Kobyłce (05-230); KRS 0000890483; NIP 125-171-55-47;
            REGON 388115171.
          </span>
        </label>
      </div>
      <div className="element-to-right">
        <button
          type="submit"
          disabled={
            !legal ||
            fullName === "" ||
            (email === "" && phone === "") ||
            message === ""
          }
          className="button button--orange"
        >
          {isSending ? "Wysyłanie..." : "Wyślij zapytanie"}
        </button>
      </div>
    </form>
  );
};

export default ContactForm;
